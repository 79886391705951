<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Bon de livraison de la facture N° {{paiementForm.get('numFacture')?.value}}">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="paiementForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="dateLivraison">Date de livraison<span class="text-danger">*</span></label>
                    <input type="date" formControlName="dateLivraison" class="form-control" id="dateLivraison"
                      [ngClass]="{
                                'is-invalid':
                                  paiementForm.get('dateLivraison')?.invalid &&
                                  paiementForm.get('dateLivraison')?.touched
                              }" />
                    <div *ngIf="
                                paiementForm.get('dateLivraison')?.invalid &&
                                paiementForm.get('dateLivraison')?.touched
                              " class="invalid-feedback">
                      <div *ngIf="paiementForm.get('dateLivraison')?.errors?.['required']">
                        La date de livraison est requise.
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-6">
                  <label for="dateLivraison"><span class="text-danger"></span></label>
                  <div class="d-flex justify-content-end">
                    <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                      <span class="spinner-grow spinner-grow-sm" role="status"></span>
                      Traitement...
                    </button>

                    <!-- Bouton "Enregistrer" -->
                    <button type="submit" class="btn btn-primary" [disabled]="paiementForm.invalid" *ngIf="!isLoading">
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-bon-livraison [paiementForm]="paiementForm"></app-table-bon-livraison>
    </app-card>
  </div>
</div>