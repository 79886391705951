// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { PaiementReponse } from '../reponse/paiement.reponse';
import { VenteVehiculeService } from '../../gestion-location-vente/vente-vehicule/service/vente.vehicule.service';
import { VenteVehiculeModele } from '../../gestion-location-vente/vente-vehicule/modeles/vente.vehicule.modele';
import { TableBonComponent } from '../table-bon/table-bon.component';

@Component({
  selector: 'app-bon-livraison',
  standalone: true,
  imports: [CommonModule, SharedModule, TableBonComponent],
  templateUrl: './bon.component.html',
  styleUrl: './bon.component.scss'
})
export class BonComponent implements OnInit {
  @ViewChild(TableBonComponent) enfantComponent!: TableBonComponent;

  paiementForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  numFacture: any = ""


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private venteVehiculeService: VenteVehiculeService
  ) {

    this.paiementForm = this.fb.group({
      dateLivraison: ['', [Validators.required]],
      idCommande: ['', [Validators.required]],
      numFacture: ['', [Validators.required]],
    });

  }

  refreshTable() {
    this.enfantComponent.initializeDataTable(true);
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.paiementForm.valid) {
      const payload: VenteVehiculeModele = {} as VenteVehiculeModele;
      payload.dateLivraison = this.paiementForm.get('dateLivraison')?.value;
      payload.idCommande = this.paiementForm.get('idCommande')?.value;

      this.venteVehiculeService.createDateLivraison(payload).subscribe(
        (response: PaiementReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Bon de livraison ajouté avec succès.';
          this.paiementForm.reset();
          this.refreshTable();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
