import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaisseComponent } from './caisse/caisse.component';
import { RetourComponent } from './retour/retour.component';
import { BonComponent } from './bon/bon.component';

const routes: Routes = [

  {
    path: 'paiement',
    component: CaisseComponent
  },
  {
    path: 'retour',
    component: RetourComponent
  },

  {
    path: 'creation-de-livraison',
    component: BonComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionCaisseRoutingModule { }
