<div class="row">
  <div class="col-sm-12">

    <div class="card border">

      <div class="card-body">
        <div *ngIf="hasSuccess" class="alert alert-success mt-0 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-0 mb-2">
          <p>{{errorMessage}}</p>
        </div>

        <form [formGroup]="venteForm" (ngSubmit)="onSubmit()">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label">N de facture</label>
              <input type="text" class="form-control " formControlName="numFacture" />
            </div>
            <div class="d-flex justify-content-end my-3">
              <button *ngIf="isLoading" class="btn btn-primary btn-sm" type="submit" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>
              <button type="submit" class="btn btn-secondary btn-sm" [disabled]="!venteForm.valid" *ngIf="!isLoading">
                Rechercher
              </button>

            </div>
          </div>

        </form>


        <div class="table-responsive">

          <div class="d-flex justify-content-end my-3">



            <!-- Print Button -->
            <button *ngIf="(vehicule$ | async) as v" [disabled]="!v.dateLivraison" class="btn btn-primary btn-sm"
              (click)="printDiv('invoice')">
              Imprimer
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>


<div style="display: none;" *ngIf="vehicule$ | async as v">
  <div class="" id="invoice">
    <div class="page">
      <div class="content">
        <div class="px-5 pt-4">
          <div class="text-start"><img src="/assets/images/logo.png" alt="Logo" width="150px"></div>
          <hr class="mt-4">
          <div class="d-flex justify-content-between" style="margin-top: 10px; margin-bottom: 10px;">
            <div style="width: 40%;"><span class="fs-4 text-uppercase fw-bold">BON DE LIVRAISON</span></div>
            <div style="width: 20%; font-size: small;"><span>Client :</span><br><span *ngIf="!v.client.nomEntreprise"
                class="fw-bold">{{v.client.nom}}
                {{v.client.prenoms}}</span>
              <span *ngIf="!v.client.nomEntreprise" class="fw-bold">{{v.client.nomEntreprise}}
              </span>
            </div>
            <div style="width: 20%; font-size: small;"><span>N° C.C. :</span><br><span class="fw-bold"></span>
            </div>
            <div style="width: 20%; font-size: small;"><span>Téléphone :</span><br><span
                class="fw-bold">{{v.client.telephone}}</span>
            </div>
          </div>
          <hr class="">
          <div class="row py-3">
            <div class="offset-5 col-7" style="font-size: small;">
              <div class="row">
                <div class="col-4">
                  <span class="fw-bold">N° Bon</span>
                  <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                  <span>{{v.numeroLivraison}}</span>
                </div>

                <div class="col-4">
                  <span class="fw-bold">Date</span>
                  <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                  <span>{{v.dateLivraison| date:'dd-MM-yyyy'}}</span>
                </div>
                <div class="col-4">
                  <span class="fw-bold">Emetteur</span>
                  <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                  <span>{{v.livrerPar}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5" style="font-size: small;">
            J'atteste avoir livré le(s) véhicule(s) dans la facture <span class="fw-bold">{{ v.numero }}</span>.
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="px-5 text-center" style="font-size: x-small;">
          <hr>
          <span>Situé à Angré 8ème tranche non loin du carrefour de la prière.</span><br>
          <span>Tel : +225 : 07 07 93 97 12 / 07 07 94 08 08 /05 06 72 68 83 13 BP 1715 Abidjan
            13</span><br>
          <span>N°CC : 1912797 L Réel Normal d’Imposition Centre des Impôts de la Djibi N° RC :
            CI-ABJ-2019-B-
            02715 NSIA N° 035361963575</span><br>
          <span>IBAN : CI93 CI04 2012 - Compte Bancaire BIAO N° 03536196357524 - SWIFT Code :
            BIAOCIABXXX</span><br>
          <span>Email : {{emailEntreprise}}</span><br>
        </div>
      </div>
    </div>
  </div>
</div>